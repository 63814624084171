// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

var Outlet = {};

function get(t, key) {
  return Caml_option.null_to_opt(t.get(key));
}

var SearchParams = {
  get: get
};

var $$URL = {};

var RouterRequest = {};

var Await = {};

var Link = {};

var NavLink = {};

var LinkWithOpts = {};

export {
  Outlet ,
  SearchParams ,
  $$URL ,
  RouterRequest ,
  Await ,
  Link ,
  NavLink ,
  LinkWithOpts ,
}
/* No side effect */
